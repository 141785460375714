/*--------------------------------------------------------------
# Content Image Overlap

1. Mobile
2. Desktop lg
3. Desktop xl
--------------------------------------------------------------*/

.uds-image-overlap {
  position: relative;
  padding-top: $uds-size-spacing-3;
  width: 100%;

  &:before {
    content: '';
    height: $uds-size-spacing-3;
    width: calc(100% - #{$uds-size-spacing-8});
    background-color: $uds-color-base-gold;
    position: absolute;
    left: $uds-size-spacing-4;
    top: 0;
  }

  .content-wrapper {
    background-color: $uds-color-base-white;
    padding: 32px;
    overflow: hidden;

    h1, h2, h3, h4, h5 {
      margin-top: 0;
    }
  }
}

/*------------------------------------------------------------------
2. Desktop, large
------------------------------------------------------------------- */
@include media-breakpoint-up(lg) {
  .uds-image-overlap {
    max-height: 100%;

    &:before {
      display: none;
    }

    display: grid;
    grid-template-columns: 1.5rem 1fr 20rem 10rem 1.5rem;
    grid-template-rows: $uds-size-spacing-9 1fr $uds-size-spacing-9;
    gap: 0px 0px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      grid-column: 2 / span 2;
      grid-row: 1 / span 3;
    }

    .content-wrapper {
      padding: 40px;
      grid-column: 3 / span 2;
      grid-row: 2 / span 1;
    }

    &:after {
      content: '';
      width: 1.5rem;
      background-color: $uds-color-base-gold;
      grid-column: 1 / 2;
      grid-row: 2 / span 1;
    }
  }

  .uds-image-overlap.content-left {
    grid-template-columns: 1.5rem 10rem 20rem 1fr 1.5rem;

    img {
      grid-column: 3 / span 2;
      grid-row: 1 / span 3;
    }

    .content-wrapper {
      grid-column: 2 / span 2;
      grid-row: 2 / span 1;
    }

    &:after {
      grid-column: 5 / 6;
      grid-row: 2 / span 1;
    }
  }
}


