.icon-small {
  font-size: $icon-small;
}

.icon-base {
  font-size: $icon-base;
}

.icon-large {
  font-size: $icon-large;
}

.icon-xl {
  font-size: $icon-xl;
}

.icon-xxl {
  font-size: $icon-xxl;
}
