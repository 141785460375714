/*------------------------------------------------------------------
Cards - Table of Contents

1. Basic badge styles
2. Badges within cards
-------------------------------------------------------------------*/

.badge {}


/*------------------------------------------------------------------
1. Basic Cards
--------------------------------------------------------------------*/

.card .badge {
  font-size: 0.75rem;
  padding: $uds-component-button-padding-y-small $uds-component-button-padding-x-small;
  margin-top: 8px;
  line-height: 1.5;
}
