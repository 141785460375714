.uds-inset-box {
  $parent: &;

  &-container {
    display: $uds-inset-box-container-display;
    justify-content: $uds-inset-box-container-justify-content;
    padding: $uds-size-spacing-6;
    width: 100%;

    &.image-background{
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    @media screen and (max-width: $uds-breakpoint-md) {
      padding: $uds-size-spacing-3;
    }
  }

  &-content {
    background-color: $uds-color-background-white;
    border: 1px solid $uds-color-base-gray-3;
    max-width: $uds-breakpoint-xxxl;
    padding: $uds-size-spacing-4 $uds-size-spacing-8;
    width: $uds-inset-box-content-basic-width;

    #{$parent}-buttons {
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
        .btn {
          display: inline;
          padding-right: 0;
          font-size: .75rem;
          padding: .25rem .75rem;
          line-height: 1.5;
          margin: 0;
        }
    }

    @media screen and (max-width: $uds-breakpoint-lg) {
      padding: $uds-size-spacing-4;
    }

    @media screen and (max-width: $uds-breakpoint-md) {
      padding: $uds-size-spacing-2;
      width: $uds-inset-box-content-small-width;

      .btn {
        font-size: 0.75rem;
        padding: $uds-component-button-padding-y-small
          $uds-component-button-padding-x-small;
        line-height: 1rem;
      }
    }
  }
}
