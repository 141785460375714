// ASU Design System buttons

// Bootstrap variable overrides for buttons
$btn-border-radius: $uds-component-border-radius;
$btn-border-radius-lg: $uds-component-border-radius;
$btn-border-radius-sm: $uds-component-border-radius;
$btn-padding-y: $uds-component-button-padding-y;
$btn-padding-x: $uds-component-button-padding-x;
$btn-disabled-opacity: 0.5;
$btn-line-height: 1rem;
$btn-transition: 0.03s ease-in-out;
// $btn-close-padding-top: 0;
// $btn-close-padding-bottom: 0.125rem;

// Bootstrap variable overrides for badges
$badge-border-radius: $uds-component-border-radius;
